import React from "react";
import Layout from "../../components/Layout";
import { navigate } from "gatsby-link";

import qs from "qs";

import axios from "axios";

import jwt from "jsonwebtoken";
// import Sidenav from './sidenav'
// import DashboardBody from './dashboardbody'

// import '../../components/Styles/Custom.scss'
import "../../components/Styles/Login.scss";

import NotificationPopup from "../../components/NotificationPopup";

import { GoogleReCaptchaProvider, GoogleReCaptcha, withGoogleReCaptcha } from 'react-google-recaptcha-v3';

let apiURL = "https://api.globelynx.com/api/"

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class ReCaptchaComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      formView: true,
      notification: false,
      notificationmsg: "",
      notificationstatus: "success",
      googleToken: "",
      errormessage: ""
    };

    if (typeof window !== "undefined") {
      if (localStorage.getItem("username") === null) {
        navigate("/login");
      } else {
        var timestamp_now = Math.floor(Date.now() / 1000);
        let token = localStorage.getItem('username');
        token = JSON.parse(token)
        if (timestamp_now <= token.expires) {
          navigate("/dashboard");
        } else {
          navigate("/login");
        }
      }
    }

    this.changeFormView = this.changeFormView.bind(this);
    this.handleRegisterSubmit = this.handleRegisterSubmit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showNotification = this.showNotification.bind(this);
  }

  async componentDidMount() {

    const token = await this.props.googleReCaptchaProps.executeRecaptcha('contact');
    this.setState({
      googleToken: token
    })

    localStorage.clear()
  }




  showNotification() {
    this.setState((prevState) => ({
      notification: !prevState.notification,
    }));

    setTimeout(
      function () {
        this.setState({ notification: false });
      }.bind(this),
      2000
    );
  }

  changeFormView() {
    this.setState((prevState) => ({
      formView: !prevState.formView,
    }));
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRegisterSubmit = (e) => {
    e.preventDefault();

    let data = {
      email: this.state.register_username,
      password: this.state.register_password,
      firstName: this.state.register_firstName,
      lastName: this.state.register_lastName,
    };
    axios
      .post(
        apiURL + "auth/register",
        data
      )
      .then((res) => {
        this.showNotification();
        this.setState({
          notificationmsg: "New user has been created.",
          notificationstatus: "success",
        });
        window.location.reload();
      })
      .catch(function (error) {
        this.showNotification();
        this.setState({
          notificationmsg: "Something went wrong.",
          notificationstatus: "error",
        });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    var self = this
    axios.post(apiURL + "auth/recaptcha", {
      token: this.state.googleToken
    })
      .then(res1 => {
        if (res1.status == 200 && res1.data.message == "verified") {
          axios
            .post(
              apiURL + "admin/login",
              // "http://localhost:8080/api/admin/login",
              {
                username: this.state.username,
                password: this.state.password
              })
            .then(res => {
              console.log(res)
              if (res.status == 200) {
                try {
                  jwt.verify(
                    res.data.token,
                    "0a6b944d-d2fb-46fc-a85e-0295c986cd9f",
                    function (err, decoded) {
                      if (typeof window !== "undefined") {
                        localStorage.setItem("username",
                          JSON.stringify({
                            name: self.state.username,
                            expires: decoded.exp
                          })
                        );
                        window.location.href = "/dashboard";
                      }
                    }
                  );
                } catch (err) {
                  this.setState({
                    errormessage: res.data.message + "\n attempts " + res.data.limiter.remaining + " remaining"
                  })
                }
              }
            })
            .catch(error => {
              let res_obj = JSON.stringify(error);
              res_obj = JSON.parse(res_obj)
              if (res_obj.response.data.stack.includes("You have 1 loggin attempt left")) {
                alert(
                  "You have 1 loggin attempt left"
                )
              }
            });
        } else {
          alert("We were unable to log you in.")
          if (typeof window !== 'undefined') {
            window.location.reload();
          }
        }
      }).catch(err => {
        alert("We were unable to log you in.")
        if (typeof window !== 'undefined') {
          window.location.reload();
        }
      })
  };

  render() {
    // return <div />;
    return (
      <React.Fragment>
        <div style={{ display: this.state.notification ? "block" : "none" }}>
          <NotificationPopup status={this.state.notificationstatus}>
            {this.state.notificationmsg}
          </NotificationPopup>
        </div>

        <div
          className="limiter"
          style={{ display: this.state.formView ? "block" : "none" }}
        >
          <div className="container-login100">
            <div className="wrap-login100">
              <form
                className="login100-form"
                method="post"
                onSubmit={this.handleSubmit}
              >
                <span className="login100-form-title p-b-26">Login</span>
                <span className="login100-form-title p-b-48">
                  <i className="zmdi zmdi-font" />
                </span>

                <div className="wrap-input100 validate-input">
                  <input
                    className="input100"
                    type="text"
                    name="username"
                    placeholder="Username or Email"
                    onChange={this.handleChange}
                  />
                </div>

                <div className="wrap-input100 validate-input">
                  <span className="btn-show-pass">
                    <i className="zmdi zmdi-eye" />
                  </span>
                  <input
                    className="input100"
                    type="password"
                    name="password"
                    placeholder="Password"
                    onChange={this.handleChange}
                  />
                </div>

                <div className="container-login100-form-btn">
                  <div className="wrap-login100-form-btn" style={{
                    borderRadius: 10
                  }}>
                    <div className="login100-form-bgbtn" />
                    <input
                      className="login100-form-btn"
                      type="submit"
                      value="login"
                    />
                  </div>
                </div>

                <div style={{ textAlign: "center" }}>

                  <p style={{
                    color: 'red',
                    fontSize: 12,
                    textAlign: 'center'
                  }}> {this.state.errormessage} </p>



                  <span className="txt1">Don’t have an account?</span>

                  <span
                    className="txt2"
                    style={{ cursor: "pointer" }}
                    onClick={this.changeFormView}
                  >
                    Sign Up
                  </span>
                </div>


                <div style={{ textAlign: "center" }}>
                  <p style={{
                    color: '#aaa',
                    fontSize: 12,
                    textAlign: 'center'
                  }}> This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" style={{
                    color: '#4e5863'
                  }}>Privacy Policy</a> <br />and <a href="https://policies.google.com/terms" style={{
                    color: '#4e5863'
                  }}>Terms of Service</a> apply.</p>
                </div>


              </form>
            </div>
          </div>
        </div>

        <div
          className="limiter"
          style={{ display: this.state.formView ? "none" : "block" }}
        >
          <div className="container-login100">
            <div className="wrap-login100">
              <form
                className="login100-form"
                method="post"
                onSubmit={this.handleRegisterSubmit}
              >
                <span className="login100-form-title p-b-26">Register</span>
                <span className="login100-form-title p-b-48">
                  <i className="zmdi zmdi-font" />
                </span>

                <div className="wrap-input100 validate-input">
                  <input
                    className="input100"
                    type="text"
                    name="register_username"
                    placeholder="Username or Email"
                    onChange={this.handleChange}
                  />
                </div>

                <div className="wrap-input100 validate-input">
                  <span className="btn-show-pass">
                    <i className="zmdi zmdi-eye" />
                  </span>
                  <input
                    className="input100"
                    type="password"
                    name="register_password"
                    placeholder="Password"
                    onChange={this.handleChange}
                  />
                </div>

                <div className="wrap-input100 validate-input">
                  <span className="btn-show-pass">
                    <i className="zmdi zmdi-eye" />
                  </span>
                  <input
                    className="input100"
                    type="text"
                    name="register_firstName"
                    placeholder="First Name"
                    onChange={this.handleChange}
                  />
                </div>

                <div className="wrap-input100 validate-input">
                  <span className="btn-show-pass">
                    <i className="zmdi zmdi-eye" />
                  </span>
                  <input
                    className="input100"
                    type="text"
                    name="register_lastName"
                    placeholder="Last name"
                    onChange={this.handleChange}
                  />
                </div>

                <div className="container-login100-form-btn">
                  <div className="wrap-login100-form-btn">
                    <div className="login100-form-bgbtn" />
                    <input
                      className="login100-form-btn"
                      type="submit"
                      value="Sign Up"
                    />
                  </div>
                </div>

                <div style={{ textAlign: "center" }}>
                  <span className="txt1">Already have an account?</span>
                  <span
                    className="txt2"
                    style={{ cursor: "pointer" }}
                    onClick={this.changeFormView}
                  >
                    Login
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}


const YourReCaptchaComponent = withGoogleReCaptcha(ReCaptchaComponent);

export default class Index extends React.Component {
  render() {
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey="6LfLtMwZAAAAAFv71Y-Qz3yhYbdaPyudIFjoFyv7"
        useRecaptchaNet="true"
      >
        <YourReCaptchaComponent />
      </GoogleReCaptchaProvider>
    );
  }
}